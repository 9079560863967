body {
  margin: 0;
  font-family: 'Nunito';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*  Font Family:  link(href='https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap', rel='stylesheet') */

/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7psDJB9cme_xc.2fe59e1e.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7nsDJB9cme.ea8f809a.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lCdi18S0xR41YDw.b973b23f.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lCds18S0xR41.df7f003c.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclSdi18S0xR41YDw.b605e515.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclSds18S0xR41.78299ddc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lujVj9_mf.62f51b45.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/static/media/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7lujVj9w.8d763566.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRdu3cOWxy40.399a2166.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/static/media/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu3cOWxw.0d9bd966.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmRdu3cOWxy40.a2d2ea5b.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/static/media/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu3cOWxw.80ce797a.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* jellee */
/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Jellee-Roman";
  src: url(/static/media/Jellee-Roman.083c709f.eot); /* IE9 Compat Modes */
  src: url(/static/media/Jellee-Roman.083c709f.eot?#iefix) format("embedded-opentype"), 
  url(/static/media/Jellee-Roman.ea199563.otf) format("opentype"), 
  url(/static/media/Jellee-Roman.665fe1a2.svg) format("svg"), 
  url(/static/media/Jellee-Roman.6bb8ef10.ttf) format("truetype"), 
  url(/static/media/Jellee-Roman.80e1bb55.woff) format("woff"), 
  url(/static/media/Jellee-Roman.83bd4119.woff2) format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* nunito */
/* font converted using https://transfonter.org/ */
@font-face {
  font-family: 'Nunito';
  src: url(/static/media/subset-Nunito-Light.4bce8753.eot);
  src: local('Nunito Light'), local('Nunito-Light'),
  url(/static/media/subset-Nunito-Light.4bce8753.eot?#iefix) format('embedded-opentype'),
  url(/static/media/subset-Nunito-Light.432125dc.woff2) format('woff2'),
  url(/static/media/subset-Nunito-Light.6fe593c4.woff) format('woff'),
  url(/static/media/subset-Nunito-Light.b19a1d8b.ttf) format('truetype'),
  url(/static/media/subset-Nunito-Light.869f3b02.svg#Nunito-Light) format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url(/static/media/subset-Nunito-Bold.e6cbd411.eot);
  src: local('Nunito Bold'), local('Nunito-Bold'),
  url(/static/media/subset-Nunito-Bold.e6cbd411.eot?#iefix) format('embedded-opentype'),
  url(/static/media/subset-Nunito-Bold.7db8f2ed.woff2) format('woff2'),
  url(/static/media/subset-Nunito-Bold.1a95f509.woff) format('woff'),
  url(/static/media/subset-Nunito-Bold.4d241260.ttf) format('truetype'),
  url(/static/media/subset-Nunito-Bold.ed5e9c84.svg#Nunito-Bold) format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url(/static/media/subset-Nunito-Regular.ca87c586.eot);
  src: local('Nunito Regular'), local('Nunito-Regular'),
  url(/static/media/subset-Nunito-Regular.ca87c586.eot?#iefix) format('embedded-opentype'),
  url(/static/media/subset-Nunito-Regular.bc35c08b.woff2) format('woff2'),
  url(/static/media/subset-Nunito-Regular.66d86e5d.woff) format('woff'),
  url(/static/media/subset-Nunito-Regular.0fc61d2e.ttf) format('truetype'),
  url(/static/media/subset-Nunito-Regular.77b2fed4.svg#Nunito-Regular) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* GENERAL */
.App {
  text-align: center;
  background-color: #c8d7e0;
}

ul{
  padding: 0 20px;
}

.wrapper{
  max-width: 1280px;
  margin: 0 auto;
}

.evasis-font{
  font-family: 'Jellee-Roman', sans-serif;
}


.divider{
  background: #e0dde5;
  height: 10px;
  width: 100%;
}

.fw{
  width: 100%;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}


hr { display: block; height: 1px;
  border: 0; border-top: 1px solid #6daad4;
  margin: 1em 0; padding: 0; }

.button {

  border: none;

  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin-bottom: 15px;
}

.button.success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.button.danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.button.warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.button.info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.button.light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.mr-button{
  margin-right: 10px;
}

.text-left{
  text-align: left;
}

/* HEADER */
.App-logo {
  width: 100px;
  margin-bottom: 8px;
}

.App-header {
  padding: 15px;
  background-color: #018ec3;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-family: 'Jellee-Roman', sans-serif;
}

.App-header h1{
  border-top: 1px solid #6daad4;
  font-size: 14px;
  margin: 0;
  padding-top: 2px;
}

.arrow-down {
  position: absolute;
  z-index: 1;
  top: 115px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid #018ec3;
}

.header-wrapper{
  width: 100%;
  max-width: 1280px;
  position: relative;
}

.buy-now-button {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana, sans-serif;
  position: absolute;
  top: -60px;
  right: 3px;
  cursor: pointer;

  /* text styles */
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #797777;
  text-decoration: none;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  /* box styles */
  display: inline-block;
  height: 39px;
  padding: 10px 15px;
  border: 1px solid;
  border-color: #2d86b6 #24659e #255796;
  border-radius: 21px;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.2) inset, 0 1px 1px rgba(1, 4, 8, 0.2);

  background-color: #ffffff;

}
.buy-now-button:hover {
  text-decoration: none;
  border-color: #377cae #175a9c #0c4893;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.2) inset, 0 1px 1px rgba(5, 64, 140, 0.2);

  background-color: #ededed;
  *zoom: 1;
}
.buy-now-button:active {
  border-color: #094b84 #094b84 #0f4585;
  box-shadow: 0 1px 1px rgba(241, 243, 247, 0.2), 0 0 20px rgba(0, 0, 0, 0.5) inset;

  background-color: #d8efff;
  *zoom: 1;
}


/* FOOTER */
.footer{
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #313D5A;
  color: #c8d7e0;
  font-size: 14px;
}

.link{
  padding-top: 4px;
  font-size: 16px;
  color: #c8d7e0;
  text-decoration: none;
}

.footer-list
{
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-list li{
    display: inline-block;
    padding-right: 5px;
}

.footer-list li:after{
    padding-left: 5px;
    content: '|';
}

.footer-list li:last-child::after{
    content: '';
}

/* VIDEO */
.volume-notification{
  position: absolute;
  z-index: 2;
  bottom: 60px;
  right: 80px;
  background-color: #018ec3;
  width: 100px;
  border-radius: 3px;
  color: #ffffff;

  -webkit-animation: bounce 1.2s ease-out;
  animation: bounce 1.2s ease-out;
  -webkit-animation-iteration-count: 3;
          animation-iteration-count: 3;
}

.volume-info-mobile{
  display: none;
}

.volume-info-mobile .volume-notification{
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 40%;
}

.volume-notification span{
  padding: 20px;
  display: block;
}


.volume-arrow-down {
  position: absolute;
  left: 33%;
  bottom: -20px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #018ec3;
}

@media only screen and (max-width: 767px)
{
  .volume-info-mobile{
    display: block;
  }

  .volume-info-web{
    display: none;
  }
}

.iframe{position:relative;width:100%;height:0;padding-bottom:56.27198%;}
.iframe iframe{position:absolute;top:0;left:0;width:100%;height:100%;}



/* ANIMATIONS */
@-webkit-keyframes bounce {
  0% {
    -webkit-transform:translateY(-100%);
  }
  5% {
    -webkit-transform:translateY(-100%);
  }
  15% {
    -webkit-transform:translateY(0);
    padding: 4px auto;
  }
  20% {
    -webkit-transform:translateY(-80%);
  }
  25% {
    -webkit-transform:translateY(0%);
    padding: 4px auto;
  }
  30% {
    -webkit-transform:translateY(-70%);
  }
  35% {
    -webkit-transform:translateY(0%);
    padding: 5px auto;
  }
  40% {
    -webkit-transform:translateY(-60%);
  }
  45% {
    -webkit-transform:translateY(0%);
    padding: 5px auto;
  }
  50% {
    -webkit-transform:translateY(-50%);
  }
  55% {
    -webkit-transform:translateY(0%);
    padding: 6px auto;
  }
  60% {
    -webkit-transform:translateY(-30%);
  }
  65% {
    -webkit-transform:translateY(0%);
    padding: 6px auto;
  }
  70% {
    -webkit-transform:translateY(-15%);
  }
  75% {
    -webkit-transform:translateY(0);
    padding: 8px auto;
  }
  80% {
    -webkit-transform:translateY(-10%);
  }
  85% {
    -webkit-transform:translateY(0);
    padding: 8px auto;
  }
  90% {
    -webkit-transform:translateY(-5%);
  }
  95% {
    -webkit-transform:translateY(0);
    padding: 10px;
  }
  100% {
    -webkit-transform:translateY(0);
    padding: 10px;
  }
}

/* Mozilla Firefox 15 below */

/* Opera 12.0 */

/* W3, Opera 12+, Firefox 16+ */
@keyframes bounce {
  0% {
    transform:translateY(-100%);
    opacity: 0;
  }
  5% {
    transform:translateY(-100%);
    opacity: 0;
  }
  15% {
    transform:translateY(0);
    padding-bottom: 5px;
  }
  30% {
    transform:translateY(-50%);
  }
  40% {
    transform:translateY(0%);
    padding-bottom: 6px;
  }
  50% {
    transform:translateY(-30%);
  }
  70% {
    transform:translateY(0%);
    padding-bottom: 7px;
  }
  80% {
    transform:translateY(-15%);
  }
  90% {
    transform:translateY(0%);
    padding-bottom: 8px;
  }
  95% {
    transform:translateY(-7%);
  }
  97% {
    transform:translateY(0%);
    padding-bottom: 9px;
  }
  99% {
    transform:translateY(-3%);
  }
  100% {
    transform:translateY(0);
    padding-bottom: 9px;
    opacity: 1;
  }
}

/* PRODUCTS */
.products{
  padding: 40px 80px;
  background-color: #f4f2f3;
}

.products .gallery{
  max-width: 600px;
  margin: 0 auto;
}

.products .gallery .gallery-main{
  padding-bottom: 20px;
}

.products .gallery .gallery-thumb{
  cursor: pointer;
  padding: 10px;
}

.products .gallery .gallery-thumb{
  cursor: pointer;
}

.products .gallery .thumb-list{
  margin-top: 30px;
  padding: 20px 10px;
  border: 2px dashed #4070a7;
  border-radius: 5px;
}


/* MAIN */
.main{
  /*background-color: #c8d7e0;*/
  padding: 40px 80px;
  background-color: #f4f2f3;
}

.main p{
  font-size: 16px;
  color: #424656;
  text-align: left;
  padding: 5px 0;
}

.camera-img{
  max-width: 276px;
  width: 100%;
}

.circle-img{
  max-width: 250px;
  width: 100%;
  margin-bottom: -50px;
  margin-left: -40px;
}

.mini-img-group{
  text-align: left;
  font-family: 'Jellee-Roman', sans-serif;
  color: #4070a7;
}

.mini-img-group .mini-img{
  width: 63px;
}

.list{
  color: #4070a7;
  font-size: 16px;
  text-align: left;
}

.list.list-dark{
  color: #424656;
  font-size: 16px;
}

.list li{
  padding-bottom: 5px;
}

.title-main{
  font-size: 27px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-family: 'Jellee-Roman', sans-serif;
  color: #2e62ac;
  text-align: left;
}

.title-item{
  font-size: 27px;
  position: relative;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 10px;
  color: #eb6035;
  text-align: left;
}

.title-item.hue{
  color: #eb6035;
}

.title-item.earthquake{
  color: #206ead;
}

.title-item.plug{
  color: #8c348b;
}

.title-item.control{
  color: #278448;
}

.home-img{
  position: relative;
}

.home-img h3{
  color: #0a256c;
  font-size: 40px;
  font-family: 'Jellee-Roman', sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}

.img-with-icon{
  position: relative;
}
.img-with-icon span:first-child{
  width: 20%;
  position: absolute;
  top: -15px;
  left: 5px;
  z-index: 1;
}

.img-with-icon span:last-child img{
  border-radius: 5px;
}

/* EKOSISTEM */
.ekosistem-container{
  padding: 40px 0;
  background-color: #f4f2f3;
  text-align: left;
}

.ekosistem-container h2{
  padding: 0 40px;
}

.ekosistem{
  width: 100%;
  max-width: 700px;
}

/* EV PLAN */
.evplan-container{
  padding: 40px 0;
  background-color: #edebec;
  text-align: center;
}

.evplan-container h2{
  padding: 0 40px;
}

.evplan{
  width: 100%;
  max-width: 800px;
}

/* SOCIALS */
.socials{
  background-color: #B98B82;
  padding: 40px 0;
}

.socials h2{
  margin-bottom: 40px;
  text-align: center;
  padding: 0 40px;
  color: #ffffff;
}

.socials a{
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.socials a img{
  width: 80px;
}

/* SHOP */
.shop{
  background-color: #DBA159;
  padding: 40px 0;
  overflow: hidden;
}

.shop h2{
  margin-bottom: 40px;
  text-align: center;
  padding: 0 40px;
  color: #ffffff;
}

.market-item a{
  padding: 20px;
  text-decoration: none;
  display: block;
}

.market-item a span{
  padding: 20px;
  border-top: 1px solid #e0dde5;
  display: block;
  /*height: 80px;*/
}

.market-item a span:first-child{
  border-top: inherit;
}

.market-item a .desc{
  margin-top: -20px;
}

.market-item{
  margin: 10px 0;
  width: 300px;
  background-color: #ffffff;
  border-radius: 5px;
  display: inline-block;
}

.market-item img{
  padding-bottom: 15px;
  height: 200px;
}

/* TECHNICAL INFO */
.technicalInfo{
  background-color: #b7b7a4;
  padding: 33px 0;
}

.technicalInfo h2{
  margin-bottom: 40px;
  text-align: center;
  padding: 0 40px;
  color: #fff1e6;
}

.technicalInfo table{
  margin: 0 auto;
  text-align: left;
  color: #6b705c;
  background-color: #f0efeb;
}

.technicalInfo table tr{
  border: 1px solid #6b705c;
}

.technicalInfo table tr:nth-child(odd) td:first-child{
  background-color: #b7b7a4;
}

.technicalInfo table tr:nth-child(odd) td:last-child{
  background-color: #fff1e6;
}


.technicalInfo table td:first-child{
  background-color: #a5a58d;
  color: #fff1e6;
  font-weight: bold;
}

.technicalInfo table td{
  padding: 1em;
}

.limit-detail{
  padding: 20px;
  font-family: 'Verdana';
  font-size: 14px;
  color: #484842;
  letter-spacing: 1.3px;
  background: #f0efeb;
  margin-bottom: 45px;
}

/* VIDEOS */
.videos{
  background-color: #4E4C67;
  padding: 40px 0;
  overflow: hidden;
}

.videos h2{
  margin-bottom: 40px;
  text-align: center;
  padding: 0 40px;
  color: #ffffff;
}

.video-item span{
  padding: 20px;
  display: block;
  color: #ffffff;
  height: 80px;
}

.video-item{
  padding: 20px;
  margin: 10px 0;
  width: 300px;
  background-color: #a4a4b5;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #ffffff;
}

/* MOBILE */
@media only screen and (max-width: 767px)
{
  .camera-img{
    width: 40%;
  }

  .title-main{
    text-align: center;
  }

  .title-item{
    text-align: center;
  }

  .circle-img{
    max-width: 150px;
    padding-bottom: 25px;
  }

  .reverse-on-mobile{
    flex-direction: column-reverse;
  }

  .main{
    padding: 40px 20px;
  }

  .home-img h3{
    font-size: 20px;
    top: 10%;
  }

  .products .gallery .thumb-list{
    padding: 0;
  }

  .products .gallery .gallery-thumb{
    padding: 3px;
  }

}

/* POLICY */
.policy {
  text-align: left;
    padding: 20px;
}

/* SETUP WIZARD */
.setup-wizard{
  padding: 0;
  min-height: 450px;
  max-width: 800px;
  margin: 0 auto;
}

.setup-wizard a{
  display: inline-block;
}

.setup-wizard .title{
  background-color: white;
  padding: 30px 15px;
  font-family: Verdana;
  letter-spacing: 1px;
  font-size: 25px;
  line-height: 35px;
  color: #2e62ac;
}

.setup-wizard .image-container{
  padding-bottom: 20px;
}

.setup-wizard table.gateway-table, .setup-wizard table.gateway-table tr, .setup-wizard table.gateway-table td{
  border: 1px solid black;
  padding: 10px;
}

.setup-wizard .link-bg{
  background-color: #018ec3;
  border-radius: 3px;
  padding: 10px;
  width: 260px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.setup-wizard .link-bg a{
  color: white;
  display: block;
}

.setup-wizard .wizard-table{
  max-width: 550px;
  margin: 0 auto;
  text-align: left;
}

.setup-wizard input{
  font-size: 14px;
  padding: 5px;
}

.setup-wizard .link-bg2{
  background-color: #018ec3;
  border-radius: 3px;
  max-width: 210px;
  height: 35px;
  font-size: 15px;
  padding: 5px;
  margin: 0 auto;
}

.setup-wizard .link-bg2 a{
  color: white;
}

.setup-wizard .link-pre-space{
  font-weight: bold;
  padding-left: 3px;
}
